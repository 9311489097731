import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Nav from '../nav/Nav';
import Container from '../../common/Container';
import ContactSection from '../home/ContactSection';
import Footer from '../footer/Footer';

import servicesImageUrl from '../gallery/002.jpg';

const services = [
  {
    heading: "Audio Hire",
    descriptions: [
      "From Melbourne, Adelaide to Queensland and everywhere in between - SubTemple can provide audio services tailored to any budget and audience size. Audio Hire includes transportation of equipment, friendly crew to set up/pack down and experienced technicians/engineers to provide you with hassle-free audio reinforcement.",
      "SubTemple’s pro audio inventory ensures high fidelity audio throughout the signal chain. We stock brands such as XTA Electronics, MC2 Audio, Full Fat Audio, Funktion One, BMS, Precision Devices, PCH, Midas, Pioneer, Yamaha and LSC.",
      "We also offer Noise Management Services in compliance with the EPA’s State Environmental Protection Policy (SEPP-01) and provide detailed reports for governing councils.",
      "We are happy to compete with any written quote with respect to the quality of equipment and service. Contact us for a free quote / our inventory list."
    ],
    points: []
  },
  {
    heading: "Live Audio Engineers",
    descriptions: [
      "Our top-of-the-range live engineers have a diversity of professional experience- from years of mixing bands to careers in educating others in the industry. They can ensure only the best mix of live music will arrive at your ears.",      
    ],
    points: []    
  },
  {
    heading: "Custom LED Lighting",
    descriptions: [
      "Whether its an outdoor stage, club, art or permanent installation - Our crew can provide custom LED lighting and visual mapping tailored to your needs. Contact us for more details and to see what works we’ve previously installed.",      
    ],
    points: []  
  },
  {
    heading: "Event Production/ Management/ Operations",
    descriptions: [
      "From experience within the industry we understand that events can be a stressful undertaking. Our team have the knowledge, flexibility and intuition to manage any situation that may arise. We strive to keep events running smoothly and stress-free.  Our network of event organisers and crew can supply bookings of artists, event logistics, site management and operations. We’re able to provide services that are environmentally sustainable and diverse, contact us to discuss specifics around your event.",      
    ],
    points: []
  },
  {
    heading: "Technical Services",
    descriptions: [
      "If you need experienced engineers to help you with your sound system then look no further. Our engineers use the latest software in conjunction with their own auditory ability to analyse the sonic field. Technical services may include engineers to"      
    ],
    points: [
      'Help you set up',
      'Tune your rig to its environment',
      'Troubleshoot',
      'Optimise system settings',
      'Integrate your audio equipment'
    ]
  },
]

class Services extends Component {
  componentDidMount() { 
    window.scrollTo(0, 0);
  }  

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Services | Subtemple</title>
          <meta name="description" content="Explore the professional services to compliment our sound system and bring the best live audio experience " />
          <link rel="canonical" href="https://subtemple.com/services" />
        </Helmet>
        <Nav/>
        <main>
          <img src={servicesImageUrl} style={{objectFit: 'cover', height: '200px', width: '100%'}} alt="Live Audio Services"/>
         <Container>
           <h1>Services</h1>
            {services.map((service, i) => {
              return (
                <section key={i} style={{padding: '1rem 0'}}>
                  <h2 style={{color: 'gold'}}>{service.heading}</h2>
                  {
                    service.descriptions.map((description, i) => { 
                      return (
                        <p key={i}>{description}</p>
                      );
                    })
                  }
                  {
                    service.points.length > 0 ?
                    <ul>
                    {                    
                      service.points.map((point, i) => { 
                        return (
                          <li key={i}>{point}</li>     
                        );
                      })
                    }
                    </ul> : null
                  }
                </section>
              );
            })}   
         </Container>
         <ContactSection/>
         <Footer/>
        </main>
      </React.Fragment>
    );
  }
}
 
export default Services;