import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Nav from '../nav/Nav';
import ContactSection from '../home/ContactSection';
import Footer from '../footer/Footer';
import Container from '../../common/Container';

import subtempleLogo from './logo.jpg';
import './About.css';

const paragraphs = [
  "SubTemple began in Melbourne, Australia in 2017 as a DIY project between a team of audio, event and music enthusiasts. A collective love of music and passion for well produced events quickly escalated the project into a serious and focused endeavour. Over time two goals became the driving force for the team: high quality sound reinforcement and the facilitation of community. This desire grew into a versatile model in which Subtemple would provide its services to events large and small, forging sustainable relationships along the way.",
  "Countless long days were spent planning. How would we marry the best components and technologies on the market, with the most innovative and intelligent loudspeaker designs? We were looking to create our dream sound system. Our sound had to be clean and crystal clear. We designed the system to be as efficient as possible: producing the maximum amount of sound for power input.",
  "We wanted to use our passion and hard work to compete with the most highly-regarded commercial brands in the industry. The results speak for themselves. Experiencing this sound system is not only a sonic sensation, but a physical one. We invite you to witness it yourself.",
  "We strove not to cut any corners while designing SubTemple since a chain (audio signal chain) is only as strong as its weakest link. Information on the high quality equipment we use can be provided upon request, we’re also happy to discuss certain specifications and technical aspects of our sound system.",
  "SubTemple is backed by an enthusiastic and diverse team with an in-depth knowledge of audio engineering, LED lighting and event production. We are committed to providing a high level of technical service while maintaining our grassroots connection to the culture, scale and individual needs of every gig. Join us on our mission to facilitate, nourish and unite musicians, artists and organisers through quality audio, LED lighting and events."
]

class About extends Component {
  componentDidMount() { 
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>About | Subtemple</title>
          <meta name="description" content="Born out of our love of music and years spent in the industry, SubTemple is a Sound System and an audio hire team providing the best possible sound reinforcement." />
          <link rel="canonical" href="https://subtemple.com/about" />
        </Helmet>        
        <Nav/>
        <main>
          <Container>
            <div className="about-layout">
              <div>
                <h1 style={{color: 'gold'}}>About Us.</h1>
                {
                paragraphs.map((paragraph, i) => { 
                  return (
                    <p key={i}>{paragraph}</p>
                  );
                })
                }
              </div>
              <div>
                <img src={subtempleLogo} alt="Subtemple Artwork."/>
              </div>
            </div>           
          </Container>
          <ContactSection />
          <Footer />
        </main>
      </React.Fragment>
    );
  }
}
 
export default About;