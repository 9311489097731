import React from 'react';

const Button = (props) => {
  return ( 
    <button
      className={props.type}
      onClick={(e) => props.onClick(e)}
      style={props.style}>
      {props.content}
    </button>
  );
}
 
export default Button;