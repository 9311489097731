import url1 from './001.jpg';
import url2 from './002.jpg';
import url3 from './003.jpg';
import url4 from './004.jpg';
import url5 from './005.jpg';
import url6 from './006.jpg';
import url7 from './007.jpg';
import url8 from './008.jpg';
import url9 from './009.jpg';
import url10 from './about.jpg';
import url11 from './011.jpg';
import url12 from './012.jpg';
import url13 from './013.jpg';
import url14 from './014.jpg';
import url15 from './015.jpg';
import url16 from './016.jpg';
import url17 from './017.jpg';
import url18 from './about-2.jpg';

export const images = [
  {
    url: url1,
    altText: ''
  },
  {
    url: url2,
    altText: ''
  },
  {
    url: url3,
    altText: ''
  },
  {
    url: url4,
    altText: ''
  },
  {
    url: url5,
    altText: ''
  },
  {
    url: url6,
    altText: ''
  },
  {
    url: url7,
    altText: ''
  },
  {
    url: url8,
    altText: ''
  },
  {
    url: url9,
    altText: ''
  },
  {
    url: url10,
    altText: ''
  },
  {
    url: url11,
    altText: ''
  },
  {
    url: url12,
    altText: ''
  },
  {
    url: url13,
    altText: ''
  },
  {
    url: url14,
    altText: ''
  },
  {
    url: url15,
    altText: ''
  },
  {
    url: url16,
    altText: ''
  },
  {
    url: url17,
    altText: ''
  },
  {
    url: url18,
    altText: ''
  }  
]