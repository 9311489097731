import React from 'react';

const Container = (props) => {
  const classNames = `container ${props.className}`;
  return (    
    <div className={classNames} style={props.style}>
      {props.children}
    </div>
  );
}

export default Container;