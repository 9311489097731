import React from 'react';
import { Link } from 'react-router-dom';

import Container from '../../common/Container';
import SectionHeading from '../../common/SectionHeading';

import subtempleImage from './subtemple.svg';
import './Subtemple.css';

const Subtemple = (props) => {
  return ( 
    <section id="subtemple-section">
      <Container>
        <div className="subtemple-layout">
          <div>
            <SectionHeading name="Meet Subtemple." />
            <p>
            SubTemple is an audio-visual and events team providing quality equipment and services tailored to any budget. Our custom-designed, hand-built speaker cabinets set a new standard in audio hire. Our system is powered by our top-of-the-line amplifiers and processing gear.
            </p>
            <div className="actions">
              <Link className="button ghost" to="/about">About Us</Link>
            </div>
          </div>
          <div>
            <img src={subtempleImage} alt="Illustraition of Subtemple speakers"/>
          </div>
        </div>
      </Container>
    </section>
  );
}
 
export default Subtemple;