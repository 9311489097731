import React from 'react';

export const InputField = (props) => {
  return (  
    <fieldset>
      <label htmlFor={props.name}>{props.label}</label>
      <input
        type={props.type}
        style={props.style}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
      />
    </fieldset>
  );
}

export const TextAreaField = (props) => {
  return ( 
    <fieldset>
      <label htmlFor={props.name}>{props.label}</label>
      <textarea
        style={props.style}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}>
      </textarea>
    </fieldset>
   );
}