import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Container from '../../common/Container';
import './Nav.css';
import FacebookIcon from '../../common/social/FacebookIcon';
import InstagramIcon from '../../common/social/InstagramIcon';

import subtempleLogo from '../about/logo.jpg';

const links = [
  { 
    name: "Services",
    url: "/services"
  },
  { 
    name: "About",
    url: "/about"
  },
  { 
    name: "Gallery",
    url: "/gallery"
  },
  { 
    name: "Contact",
    url: "/contact"
  },
]

class Nav extends Component {  
  render() {
    const classNames = `shadow`;

    return (
      <nav className={classNames} style={this.props.style }>        
        <Container className="nav-wrapper">
          <Link to="/" className="nav-logo">
            <img src={subtempleLogo} alt="Subtemple Logo" width="40" className="hide-mobile"/>
            <header>Subtemple</header>
          </Link>
          <div className="nav-actions">
            <div className="nav-links">
              {links.map((link, i) => {
                return (
                  <Link key={i} className="nav-link" to={link.url}>{link.name}</Link>
                );
              })}
              <FacebookIcon className="hide-mobile" url="https://facebook.com/subtemple" size={28} />
              <InstagramIcon className="hide-mobile" url="https://instagram.com/subtemplesoundsystem" size={28}/>
            </div>            
          </div>
        </Container>
      </nav>
    );
  }
}
 
export default Nav;