import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './components/home/Home';
import About from './components/about/About';
import Services from './components/services/Services';
import Gallery from './components/gallery/Gallery';
import Contact from './components/contact/Contact';

import './App.css';

class App extends Component {
  render() {
    return (     
        <Router>        
            <Route exact path="/" component={Home}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/services" component={Services}/>         
            <Route exact path="/gallery" component={Gallery}/>                      
            <Route exact path="/contact" component={Contact}/>                              
        </Router>
    );
  }
}

export default App;