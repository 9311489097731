import React from 'react';
import { Link } from 'react-router-dom';

import Container from '../../common/Container';
import SectionHeading from '../../common/SectionHeading';
import LED from './LED';
import './Subtemple.css';

const HomeServices = (props) => {
  return ( 
    <section className="subtemple-section">
     <Container>
        <div className="subtemple-layout">
          <div>
          <SectionHeading name="Discover Our Services." />
            <p>
            We provide a range of services, from audio hire to lighting design - we can tailor make a comprehensive package for any size event. 
            Hire of all our equipment includes set-up and live engineering. 
            Our versatile team are eager to work with you to help produce your event.
            </p>
            <div className="actions">
              <Link className="button ghost" to="/services">Services</Link>
            </div>
          </div>
          <div>
            <LED/>
          </div>
        </div>
     </Container>
    </section>
   );
}
 
export default HomeServices;