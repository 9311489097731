import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Hero from '../hero/Hero';
import Subtemple from './Subtemple';
import HomeServices from './HomeServices';
import ContactSection from './ContactSection';
import Footer from '../footer/Footer';

class Home extends Component {
  componentDidMount() { 
    window.scrollTo(0, 0);
  }

  render() { 
    return (
      <React.Fragment>
        <Helmet>
          <title>Subtemple</title>
          <meta name="description" content="SubTemple is a custom built, high fidelity Sound System providing a unique auditory experience for any event." />
          <link rel="canonical" href="https://subtemple.com/" />
        </Helmet>
        <main>
          <Hero />
          <Subtemple />
          <HomeServices />
          <ContactSection />
          <Footer/>
        </main>        
      </React.Fragment>
    );
  }
}
 
export default Home;