import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import Nav from '../nav/Nav';
import Container from '../../common/Container';
import { InputField, TextAreaField } from '../../common/Input';
import Button from '../../common/Button';
import Footer from '../footer/Footer';

import { isEmailValid } from '../../services/validationService';

import './Contact.css';

const apiUrl = 'https://api.subtemple.com/api/contact';

class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      comment: '',
      isValid: false,
      isDone: false
    }

    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onSubjectChange = this.onSubjectChange.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  onNameChange(e) { this.setState({ name: e.target.value }) }
  onEmailChange(e) { this.setState({ email: e.target.value }) }
  onPhoneChange(e) { this.setState({ phone: e.target.value }) }
  onSubjectChange(e) { this.setState({ subject: e.target.value }) }
  onCommentChange(e) { this.setState({ comment: e.target.value }) }

  onSubmit(e) {
    e.preventDefault();

    let isValid = true;
    const firstName = this.state.name;
    const email = this.state.email;
    const phone = this.state.phone;
    const subject = this.state.subject;
    const comment = this.state.comment;

    if (firstName.length === "") { isValid = false; }
    if (!isEmailValid(email)) { isValid = false; }

    if (isValid === true) {
      fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({
          name: firstName,
          email: email,
          phone: phone,
          subject: subject,
          comment: comment
        })
      })
        .then(response => {
          console.log("checking response")
          if (response.status === 200) {
            console.log("response successful")
            this.setState({ firstName: "", email: "", phone: "", subject: "", comment: "", isDone: true });
          }
          else {
            alert("whoops, something went wrong. Try again later.")
          }
        })
    }    
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact | Subtemple </title>
          <meta name="description" content="Contact us now for enquiries, bookings and pricing for your next event." />
          <link rel="canonical" href="https://subtemple.com/contact" />
        </Helmet>
        <Nav />
        <main>
          <Container className="contact-layout">
            <div className="contact-info">
              <h1>Rent a Rig.</h1>
              <p>You can contact us through this form or directly from the links below.</p>
              <ul className="contact-links">
                <li>
                  <span>Hours</span>&nbsp;
                  <a href="https://www.google.com/search?q=aest+time" target="_blank" rel="noopener noreferrer">9am-5pm (AEST)</a>
                  </li>
                <li>
                  <span>Email</span>&nbsp;
                  <a href="mailto:subtemplebookings@gmail.com">subtemplebookings@gmail.com</a>
                </li>
                <li>
                  <span>Jay</span>&nbsp;
                  <a href="tel:+61421993717">+61 421 993 717</a>
                </li>                
                <li>
                  <span>Matt</span>&nbsp;<a href="tel:+61419589201">+61 419 589 201</a>
                </li>
              </ul>
            </div>
            <form className="contact-form paper">
              <InputField
                type="text"
                name="name"
                label="Name"
                value={this.state.name}
                onChange={this.onNameChange} />
              <InputField
                type="text"
                name="email"
                label="Email"
                value={this.state.email}
                onChange={this.onEmailChange} />
              <InputField
                type="text"
                name="phone"
                label="Phone"
                value={this.state.phone}
                onChange={this.onPhoneChange} />
              <InputField
                type="text"
                name="subject"
                label="Subject"
                value={this.state.subject}
                onChange={this.onSubjectChange} />
              <TextAreaField
                type="text"
                name="comment"
                label="Comment"
                value={this.state.comment}
                onChange={this.onCommentChange} />
              {
                this.state.isDone ?
                  <Link className="button success" to="/">Thank you, we'll be in touch.</Link>
                  :
                  <Button
                  content="Submit"
                  onClick={this.onSubmit}                    
                  style={{ width: '100%' }} />                    
              }           
            </form>
          </Container>
          <Footer/>
        </main>
        
      </React.Fragment>
    );
  }
}

export default Contact;