import React from 'react';
import InView from 'react-intersection-observer';

class GalleryItem extends React.Component {
  constructor(props){
    super(props)

    this.state = {      
      visibility: 'hidden'
    }

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(inView){
    this.setState({
      visibility: inView ? 'fade-slide-in': 'fade-out'
    });
  }

  render() {
    return (
      <InView
        as="div"
        className={this.state.visibility}
        onChange={this.onToggle}>
        <img src={this.props.url} alt={this.props.alt}/>
      </InView>
    );
  }
}

export default GalleryItem;