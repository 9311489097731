import React from 'react';

import Nav from '../nav/Nav';
import Button from '../../common/Button';
import Container from '../../common/Container';

import heroImage from './hero.jpg';
import './Hero.css';

const Hero = (props) => {
  return (
    <section className="hero">
      <Nav style={{position: 'absolute', top: '0'}}/> 
      <img
        src={heroImage}
        alt="Our sound system pictured across the beach"
        className="hero-image" />
      <Container>
        <div className="hero-content">
          <h1 style={{color: '#222'}}>Audio. Redefined.</h1>
          <p className="hide-mobile"><em>"SubTemple is a custom built, high fidelity Sound System providing a unique auditory experience for any event.”</em></p>       
          <span>
            <Button content="Learn more" onClick={() => {
              let top = document.querySelector('#subtemple-section').getBoundingClientRect().top;
              window.scroll({ top: top, left: 0, behavior: 'smooth' });
            }}/>            
          </span>
        </div>
      </Container>
    </section>
  );
}
 
export default Hero;