import React from 'react';

const FacebookIcon = (props) => {
  const classNames = `nav-link ${props.className}`;
  return (
    <a href={props.url} target="_blank" className={classNames} rel="noopener noreferrer">
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 72 72"
        width={props.size}
        height={props.size}>
        <title>Facebook</title>
        <path 
          style={{fill: '#4267b2'}} 
          d="M68,0H4A4,4,0,0,0,0,4V68a4,4,0,0,0,4,4H38.46V44.16H29.11V33.26h9.35v-8c0-9.3,5.68-14.37,14-14.37a77.46,77.46,0,0,1,8.38.43V21H55.1c-4.51,0-5.39,2.15-5.39,5.3v6.94H60.5l-1.4,10.9H49.71V72H68a4,4,0,0,0,4-4V4A4,4,0,0,0,68,0Z"/>
        <path 
          style={{fill: '#fff'}} 
          d="M49.71,72V44.16H59.1l1.4-10.9H49.71V26.32c0-3.15.88-5.3,5.39-5.3h5.72V11.3a77.46,77.46,0,0,0-8.38-.43c-8.3,0-14,5.07-14,14.37v8H29.11v10.9h9.35V72Z"/>
      </svg>
    </a>
  );
}

export default FacebookIcon;