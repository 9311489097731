import React from 'react';

import Container from '../../common/Container';
import FacebookIcon from '../../common/social/FacebookIcon';
import InstagramIcon from '../../common/social/InstagramIcon';

import './Footer.css';

const Footer = (props) => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <Container className="footer-wrapper">
        <div className="footer-social">
          <FacebookIcon url="https://facebook.com/subtemple" size={28} />
          <InstagramIcon url="https://instagram.com/subtemplesoundsystem" size={28}/>
        </div>
        <div style={{
          fontFamily: "'Amatic SC', cursive",
          fontSize: '2rem'}}>
          &copy;{year} Subtemple
        </div>
        <div style={{fontSize: '.9rem'}}>Made with <span>&hearts;</span> by <a href="https://hentamine.com">Hentamine</a></div>
      </Container>
    </footer>
  );
}
 
export default Footer;