import React from 'react';
import { Link } from 'react-router-dom';

import Container from '../../common/Container';
import SectionHeading from '../../common/SectionHeading';
import './Subtemple.css';

const ContactSection = (props) => {
  return ( 
    <section className="subtemple-section contact-section">      
      <Container>
        <div className="subtemple-layout">
          <div>
            <SectionHeading name="Get in touch. Lets Chat." />
            <p>For enquiries, bookings, and pricing. Please feel free to contact us.</p>
          </div>
          <div>
            <Link className="button" to="/contact">Contact Us</Link>
          </div>
        </div>
      </Container>
    </section>
  );
}
 
export default ContactSection;