import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Nav from '../nav/Nav';
import Container from '../../common/Container';
import SectionHeading from '../../common/SectionHeading';
import ContactSection from '../home/ContactSection';
import Footer from '../footer/Footer';

import { images } from './GalleryData';
import GalleryItem from './GalleryItem';

class Gallery extends Component {
  componentDidMount() { 
    window.scrollTo(0, 0);
  }

  render() { 
    return (
      <React.Fragment>
        <Helmet>
          <title>Gallery | Subtemple</title>
          <meta name="description" content="Discover our sound system through photographs of the team  building and deploying subtemple." />
          <link rel="canonical" href="https://subtemple.com/gallery" />
        </Helmet>
        <Nav/>
        <main>          
          <Container>
          <SectionHeading name="Gallery"/>
           <div className="gallery-layout">
              {
                images.map((image, i) => { 
                  return (
                    <GalleryItem url={image.url} alt={image.altText}/>
                  );
                })
              }
           </div>
          </Container>
          <ContactSection/>
          <Footer/>
        </main>
      </React.Fragment>
    );
  }
}
 
export default Gallery;