import React, { Component } from 'react';

class LED extends Component {
  constructor(props) {
    super(props)

    this.state = {
      color: {
        r: 255,
        g: 255,
        b: 255
      }
    }

    this.generateRGB = this.generateRGB.bind(this);
  }

  componentDidMount() { 
    this.intervalID = setInterval(
      () => this.generateRGB(),
      1000
    );
  }

  componentWillUnmount() { 
    clearInterval(this.intervalID);
  }

  generateRGB() {
    let color = {
      r: Math.floor(Math.random() * Math.floor(255)),
      g: Math.floor(Math.random() * Math.floor(255)),
      b: Math.floor(Math.random() * Math.floor(255))
    }

    this.setState({color});
  }


  render() {
    const rgb = `rgb(${this.state.color.r},${this.state.color.g},${this.state.color.b})`;

    const rgbStyles = {
      fill: rgb,
      stroke: rgb,
      strokeWidth: '0.44px',
      transition: '.5s'
    }

    return (      
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 800 600"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinecap: 'round',
          strokeLineJoin: 'round',
          strokeMiterlimit: '1.5',
          transition: '1s'
        }}>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,-235.006)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,-166.493)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,-97.9797)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,-29.4666)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,39.0464)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,107.559)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,176.072)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,-52.8907,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,10.5845,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,74.0597,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,137.535,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,201.01,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,264.485,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,327.961,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
        <g transform="matrix(2.28209,0,0,2.28209,391.436,244.585)">
          <circle cx="101.104" cy="129.36" r="9.272" style={rgbStyles} />
        </g>
      </svg>
    );
  }
}

export default LED;